// export * from '../helpers/YourFileName'; another way to export global JS function.

/**
 * A helper for loading Javascript pack tag on JS events (onClick, load..etc)
 * e.g: if you want to load a javascript pack tag when navigating to exercises
 * @param {string} pack Pass the pack name (should be created)
 * @param {string} src  Pass the src for the pack you would like to load
 */

export function loadJavascriptPack(pack, src, callback = null) {
  if (!document.getElementById(pack + 'JS')) {
    const javascriptPack = document.createElement('script');
    javascriptPack.src = src;
    javascriptPack.id = pack + 'JS';

    if (callback) {
      javascriptPack.onload = callback;
    }

    document.body.appendChild(javascriptPack);
  }
}

/**
 * A helper for loading Stylesheet pack tag on JS events (onClick, load..etc)
 * e.g: if you want to load a Stylesheet pack tag when navigating to exercises
 * @param {string} pack Pass the pack name (should be created)
 * @param {string} src  Pass the src for the pack you would like to load
 */

export function loadStylesheetPack(pack, src) {
  if (!document.getElementById(pack + 'CSS')) {
    const stylesheetPack = document.createElement('link');
    stylesheetPack.href = src;
    stylesheetPack.id = pack + 'CSS';
    stylesheetPack.type = 'text/css';
    stylesheetPack.rel = 'stylesheet';
    document.body.appendChild(stylesheetPack);
  }
}
